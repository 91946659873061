import React from "react"
import SmoothLink from "../SmoothLink"
import styles from "./Links.module.scss"

const Links = () => {
  return (
    <div className={styles.nav}>
      <SmoothLink link="#hello" label="hello" />
      <SmoothLink link="#skills" label="skills" />
      <SmoothLink link="#portfolio" label="portfolio" />
      <SmoothLink link="#contact" label="contact" />
    </div>
  )
}
export default Links
