import React from "react"
import homeSvg from "../../images/home.svg"
import styles from "./Home.module.scss"

const Home = () => {
  return (
    <a href="#home">
      <img src={homeSvg} alt="go back top" className={styles.homeSvg} />
    </a>
  )
}

export default Home
