import React from "react"
import styles from "./Project.module.scss"

const Tag = ({ tag }) => {
  return <div className={styles.tag}>{tag}</div>
}
const Project = ({
  title,
  description,
  imageLink,
  githubLink,
  liveLink,
  stack,
}) => {
  const tags = stack.map((tag, idx) => <Tag tag={tag} key={idx} />)
  return (
    <div className={styles.card}>
      <div className={styles.coverContainer}>
        <img src={imageLink} className={styles.cover} />
      </div>
      <div className={styles.container}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.stackContainer}>
          <div className={styles.stackTitle}>USED STACK :</div>
          <div className={styles.stackTags}>{tags}</div>
        </div>
        <div className={styles.linkContainer}>
          <a href={githubLink} target="_blank">
            Github Repository
          </a>
          <a href={liveLink} target="_blank">
            Live Demo
          </a>
        </div>
      </div>
    </div>
  )
}
export default Project
