import React from "react"
import Title from "../Title"
import styles from "./Hello.module.scss"
const Hello = ({ intro, resume }) => {
  return (
    <div className={styles.body} id="hello">
      <div className={styles.content}>
        <Title title={"Hi"} />
        <div className={styles.intro}>{intro}</div>
        <a 
        className={styles.resume}
        href={resume}
        target="_blank"
        >
            download cv
        </a>
      </div>
    </div>
  )
}
export default Hello
