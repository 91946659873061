import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Title from "../Title"
import Project from "../Project"
import styles from "./Portfolio.module.scss"
const Portfolio = ({
  projectTitles,
  projectLinks,
  projectDescriptions,
  projectGithub,
  projectCovers,
  projectStacks,
}) => {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { eq: "png" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)
  const getImageLink = imageName => {
    const imgObj = images.allFile.edges.filter(
      img => img.node.name === imageName
    )
    return imgObj[0].node.publicURL
  }
  return (
    <div className={styles.body} id="portfolio">
      <div className={styles.titleContainer}>
        <Title title={"My Projects"} />
      </div>
      <div className={styles.container}>
        {projectTitles.map((title, idx) => {
          return (
            <Project
              title={title}
              description={projectDescriptions[idx]}
              imageLink={getImageLink(projectCovers[idx])}
              githubLink={projectGithub[idx]}
              liveLink={projectLinks[idx]}
              stack={projectStacks[idx]}
              key={idx}
            />
          )
        })}
      </div>
    </div>
  )
}
export default Portfolio
