import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/Header"
import Hello from "../components/Hello"
import Skills from "../components/Skills"
import Portfolio from "../components/Portfolio"
import Contact from "../components/Contact"
import FixedNavbar from "../components/FixedNavbar"
import Home from "../components/Home"

const Index = ({ data }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        dataJson {
          details {
            phone
            email
            location
          }
          socialLinks {
            twitter
            linkedIn
            github
            medium
            devto
            hackerRank
            hackerEarth
          }
          intro
          resumeLink
          skills
          percentages
          projects {
            projectTitles
            projectLinks
            projectDescriptions
            projectGithub
            projectCovers
            projectStacks
          }
        }
      }
    `
  )

  return (
    <div>
      <SEO title="Shubham Biswas" />
      <Header
        details={data && data.dataJson.details}
        socialLinks={data && data.dataJson.socialLinks}
      />
      <Hello
        intro={data && data.dataJson.intro}
        resume={data && data.dataJson.resumeLink}
      />
      <Skills
        skills={data && data.dataJson.skills}
        percentages={data && data.dataJson.percentages}
      />
      <Portfolio
        projectTitles={data && data.dataJson.projects.projectTitles}
        projectCovers={data && data.dataJson.projects.projectCovers}
        projectLinks={data && data.dataJson.projects.projectLinks}
        projectDescriptions={data && data.dataJson.projects.projectDescriptions}
        projectGithub={data && data.dataJson.projects.projectGithub}
        projectStacks={data && data.dataJson.projects.projectStacks}
      />
      <Contact
        socialLinks={data && data.dataJson.socialLinks}
        email={data && data.dataJson.details.email}
      />
      <Home/>
      <FixedNavbar/>
    </div>
  )
}

export default Index
