import React from "react"
import ProgressBar from "../ProgressBar"
import styles from "./SkillCard.module.scss"
const SkillCard = ({skill, percent}) => {
  return (
    <div className={styles.card}>
      <div className={styles.details}>
        <span className={styles.skill}>{skill}</span>
        <span className={styles.percent}>{`${percent}%`}</span>
      </div>
      <ProgressBar percent={percent}/>
    </div>
  )
}

export default SkillCard
