import React, { useEffect, useRef, useState } from "react"
import Links from "../Links"
import styles from "./FixedNavbar.module.scss"

const FixedNavbar = () => {
  const ref = useRef(null)
  const [show, setShow] = useState(false)
  const handleScroll = () => {
    if (ref.current) {
      if (window.pageYOffset > 420 && !show) {
        setShow(true)
      } else if (window.pageYOffset < 420 && show) {
        setShow(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })
  return (
    <div
      className={
        show ? `${styles.container} ${styles.animateDown}` : styles.container
      }
      ref={ref}
    >
      <Links />
    </div>
  )
}

export default FixedNavbar
