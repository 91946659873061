import React, { useRef, useEffect, useState } from "react"
import styles from "./Title.module.scss"

const Title = ({ title }) => {
  const ref = useRef(null)
  const [triggeredFlag, setTriggeredFlag] = useState(false)
  const [animatedTitle, setAnimatedTitle] = useState([...title])
  let intervalId = null
  let animateLatestLen = 0
  const handleScroll = () => {
    if (ref.current) {
      if (
        window.pageYOffset > ref.current.offsetTop - (window.innerHeight - 5) &&
        window.pageYOffset < ref.current.offsetTop
      ) {
        if (!triggeredFlag) {
          setAnimatedTitle([])
          setTriggeredFlag(true)
        }
      }
    }
  }

  const typingEffect = () => {
      const numOfAlphabet = animateLatestLen
      if (numOfAlphabet < title.length) {
        const newTitle = [...title].splice(0, numOfAlphabet + 1)
        animateLatestLen = newTitle.length
        setAnimatedTitle(newTitle)
      } else if (numOfAlphabet === title.length && intervalId) {
        clearInterval(intervalId)
      }
  }

  useEffect(() => {
    if (triggeredFlag) {
      const intId = setInterval(typingEffect, 500)
      intervalId = intId
      return () => clearInterval(intId)
    }
  }, [triggeredFlag])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })

  return (
    <div className={styles.title} ref={ref}>
      {animatedTitle}<span className={styles.blinkingCursor}>_</span>
    </div>
  )
}
export default Title
