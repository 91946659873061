import React from "react"

import Links from "../Links"
import profilePic from "../../images/profilepic.jpg"
import twitterSvg from "../../images/twitter.svg"
import githubSvg from "../../images/github.svg"
import linkedinSvg from "../../images/linkedin.svg"
import styles from "./Header.module.scss"

const Header = ({ details, socialLinks }) => {
  return (
    <div className={styles.header} id="home">
      <div className={styles.links}>
        <Links />
      </div>
      <div className={styles.content}>
        <div className={styles.photo}>
          <img
            className={styles.jpg}
            src={profilePic}
            alt="this Is How I Look"
          />
        </div>
        <div className={styles.details}>
          <div className={styles.top}>
            <span className={styles.name}>Shubham Biswas</span>
            <span className={styles.designation}>Full-Stack Developer</span>
          </div>

          <div className={styles.bottom}>
            <div className={styles.phone}>
              <span className={styles.offwhite_text}>PHONE -</span>{" "}
              {details && details.phone}
            </div>
            <div className={styles.email}>
              <span className={styles.offwhite_text}>EMAIL -</span>{" "}
              {details && details.email}
            </div>
            <div className={styles.phone}>
              <span className={styles.offwhite_text}>LOCATION -</span>{" "}
              {details && details.location}
            </div>
          </div>

          <div className={styles.social}>
            <a href={socialLinks.twitter} target="_blank">
              <img src={twitterSvg} alt="link to my twitter account" />
            </a>
            <a href={socialLinks.github} target="_blank">
              <img src={githubSvg} alt="link to my github" />
            </a>
            <a href={socialLinks.linkedIn} target="_blank">
              <img src={linkedinSvg} alt="link to my linkedin profile" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
