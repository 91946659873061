import React from "react"
import Title from "../Title"
import SkillCard from "../SkillCard"
import styles from "./Skills.module.scss"
const Skills = ({ skills, percentages }) => {
  const row1Cards = []
  const row2Cards = []
  skills.forEach((skill, idx) => {
    if (idx % 2 === 0) {
      row1Cards.push(
        <SkillCard skill={skill} percent={percentages[idx]} key={idx} />
      )
    } else {
      row2Cards.push(
        <SkillCard skill={skill} percent={percentages[idx]} key={idx} />
      )
    }
  })
  return (
    <div className={styles.body} id="skills">
      <div className={styles.titleContainer}>
        <Title title={"Skills"} />
      </div>
      <div className={styles.container}>
        <div className={styles.row1}>{row1Cards}</div>
        <div className={styles.row2}>{row2Cards}</div>
      </div>
    </div>
  )
}
export default Skills
