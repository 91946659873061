import React from "react"
import githubSvg from "../../images/github.svg"
import linkedinSvg from "../../images/linkedin.svg"
import twitterSvg from "../../images/twitter.svg"
import mediumSvg from "../../images/medium.svg"
import devtoSvg from "../../images/devto.svg"
import mailSvg from "../../images/mail.svg"
import hackerRankSvg from "../../images/Hackerrank.svg"
import hackerEarthSvg from "../../images/hackerearth.svg"
import styles from "./Contact.module.scss"
const Contact = ({ socialLinks, email }) => {
  return (
    <div className={styles.body} id="contact">
      <div className={styles.container}>
        <a
          href={socialLinks.hackerEarth}
          alt="hacker-earth profile"
          target="_blank"
        >
          <img
            className={styles.svg}
            src={hackerEarthSvg}
            alt="hacker-earth profile"
          />
        </a>
        <a href={socialLinks.github} alt="github profile" target="_blank">
          <img className={styles.svg} src={githubSvg} alt="Github Profile" />
        </a>
        <a href={socialLinks.devto} alt="devto profile" target="_blank">
          <img
            className={styles.svg}
            src={devtoSvg}
            alt="Dev community profile"
          />
        </a>

        <a href={socialLinks.linkedIn} alt="linkedIn profile" target="_blank">
          <img
            className={styles.svg}
            src={linkedinSvg}
            alt="Linkedin profile"
          />
        </a>

        <a href={`mailto:${email}`} alt="mail me">
          <img className={styles.svg} src={mailSvg} alt="Mail me" />
        </a>

        <a href={socialLinks.twitter} alt="twitter profile" target="_blank">
          <img className={styles.svg} src={twitterSvg} alt="twitter profile" />
        </a>

        <a href={socialLinks.medium} alt="medium profile" target="_blank">
          <img className={styles.svg} src={mediumSvg} alt="medium profile" />
        </a>
        <a
          href={socialLinks.hackerRank}
          alt="hacker-rank profile"
          target="_blank"
        >
          <img
            className={styles.svg}
            src={hackerRankSvg}
            alt="hacker-rank profile"
          />
        </a>
      </div>
      <div className={styles.rights}>
        &copy; 2020 Shubham Biswas. All Rights Reserved
      </div>
    </div>
  )
}
export default Contact
