import React from "react"
import styles from "./ProgressBar.module.scss"

const ProgressBar = ({ percent }) => {
  return (
    <div className={styles.percentBar}>
      <div className={styles.percentBar_1} style={{ width: `${percent}%` }}>
        &nbsp;
      </div>
      {percent < 100 ? <div className={styles.percentBar_2}>&nbsp;</div> : ""}
    </div>
  )
}
export default ProgressBar
