import React from "react"
import styles from "./SmoothLink.module.scss"

const SmoothLink = ({ link, label }) => {
  return (
    <a href={link} className={styles.link}>
      {label}
    </a>
  )
}

export default SmoothLink
